//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import { updateHospital } from "@/lib/polkadotProvider/command/hospitals";
import countryData from "@/assets/json/country.json";
import cityData from "@/assets/json/city.json";
import { upload } from "@/lib/ipfs";
import HospitalCertification from "./Certification";
export default {
  name: "HospitalAccount",
  components: {
    HospitalCertification
  },
  async mounted() {
    const hospitalInfo = this.hospitalAccount.info;
    this.email = hospitalInfo.email;
    this.hospitalName = hospitalInfo.name;
    this.address = hospitalInfo.address;
    this.image = hospitalInfo.profileImage;
    await this.getCountries();
    this.country = hospitalInfo.country;
    this.regions = Object.entries(cityData[hospitalInfo.country].divisions);
    this.cities = Object.entries(cityData[hospitalInfo.country].divisions);
    this.region = hospitalInfo.city;
    this.city = hospitalInfo.city;
    if (this.image) {
      const res = await fetch(this.image);
      const blob = await res.blob(); // Gets the response and returns it as a blob
      const file = new File([blob], this.image.substring(21), {
        type: "image/jpg"
      });
      this.files = file;
    }
  },
  data: () => ({
    email: "",
    hospitalName: "",
    address: "",
    country: "",
    region: "",
    city: "",
    countries: [],
    regions: [],
    cities: [],
    image: "",
    files: [],
    isLoading: false,
    isUploading: false
  }),
  computed: {
    ...mapGetters({
      api: "substrate/getAPI",
      pair: "substrate/wallet",
      hospitalAccount: "substrate/hospitalAccount"
    }),
    rules() {
      return [file => !!file || "This field is required", file => !file || file.type == "image/jpg" || file.type == "image/jpeg" || "Document type should be image/jpg", file => !file || file.size <= 3097152 || "The total file size uploaded exceeds the maximum file size allowed (3MB)"];
    }
  },
  methods: {
    async getCountries() {
      this.countries = countryData;
    },
    onCountryChange(selectedCountry) {
      this.country = selectedCountry;
      this.regions = Object.entries(cityData[this.country].divisions);
    },
    onRegionChange(selectedRegion) {
      this.region = selectedRegion;
      this.cities = Object.entries(cityData[this.country].divisions);
    },
    onCityChange(selectedCity) {
      this.city = selectedCity;
    },
    async updateHospital() {
      if (!this.$refs.form.validate()) {
        return;
      }
      try {
        this.isLoading = true;
        await updateHospital(this.api, this.pair, {
          name: this.hospitalName,
          email: this.email,
          profileImage: this.image,
          address: this.address,
          country: this.country,
          city: this.city
        }, () => {
          this.isLoading = false;
        });
      } catch (err) {
        console.error(err);
      }
    },
    fileUploadEventListener(file) {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (file) {
        if (file.name.lastIndexOf(".") <= 0) {
          return;
        }
        this.isUploading = true;
        this.isLoading = true;
        const fr = new FileReader();
        fr.readAsArrayBuffer(file);
        const context = this;
        fr.addEventListener("load", async () => {
          // Upload
          const uploaded = await upload({
            fileChunk: fr.result,
            fileType: file.type,
            fileName: file.name
          });
          const computeLink = "".concat(uploaded.ipfsPath[0].data.ipfsFilePath, "/").concat(uploaded.fileName);
          context.image = "https://ipfs.io/ipfs/".concat(computeLink); // this is an image file that can be sent to server...
          context.isUploading = false;
          context.isLoading = false;
        });
      } else {
        this.files = [];
        this.image = "";
      }
    }
  }
};