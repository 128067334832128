import { queryEntireHospitalDataById } from "@/lib/polkadotProvider/query/hospitals";
import store from "@/store/index";
export async function registerHospital(api, pair, data) {
  let callback = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : () => {};
  const unsub = await api.tx.hospitals.registerHospital(data).signAndSend(pair, {
    nonce: -1
  }, async _ref => {
    let {
      events = [],
      status
    } = _ref;
    await hospitalCommandCallback(api, pair, {
      events,
      status,
      callback,
      unsub
    });
  });
}
export async function updateHospital(api, pair, data) {
  let callback = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : () => {};
  const unsub = await api.tx.hospitals.updateHospital(data).signAndSend(pair, {
    nonce: -1
  }, async _ref2 => {
    let {
      events = [],
      status
    } = _ref2;
    await hospitalCommandCallback(api, pair, {
      events,
      status,
      callback,
      unsub
    });
  });
}
export async function deregisterHospital(api, pair) {
  const result = await api.tx.hospitals.deregisterHospital().signAndSend(pair, {
    nonce: -1
  });
  return result.toHuman();
}
export async function hospitalCommandCallback(api, pair, _ref3) {
  let {
    events,
    status,
    callback,
    unsub
  } = _ref3;
  if (status.isFinalized) {
    // find/filter for success events
    const eventList = events.filter(_ref4 => {
      let {
        event
      } = _ref4;
      return api.events.system.ExtrinsicSuccess.is(event);
    });
    if (eventList.length > 0) {
      store.state.substrate.hospitalAccount = await queryEntireHospitalDataById(api, pair.address);
      callback();
      unsub();
    }
  }
}